import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["partialText", "fullText", "readMore", "showLess"];

  toggleHidden() {
    [this.partialTextTarget, this.fullTextTarget, this.readMoreTarget, this.showLessTarget].forEach(function(element) {
      element.classList.toggle("hidden")
    })
  };
}
