import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "loadingScreen",
    "stripeFormContainer",
    "existingPaymentMethods",
    "addPaymentMethod",
    "creditCardButton",
    "submitExistingPaymentMethodButton",
    "addPaymentMethodButton",
  ];

  static values = {
    publishableKey: String,
    clientSecret: String,
    returnUrl: String,
  };

  connect() {
    this.stripe = Stripe(this.publishableKeyValue);
    this.elements = this.stripe.elements({
      clientSecret: this.clientSecretValue,
    });
    var card = this.elements.create("payment");
    card.mount("#card-element");
  }

  async confirmSetup(event) {
    this.loadingScreenTarget.classList.remove("hidden");
    this.stripeFormContainerTarget.classList.add("hidden");

    event.preventDefault();
    let elements = this.elements;
    const { error } = await this.stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: this.returnUrlValue,
      },
    });
    if (error) {
      this.loadingScreenTarget.classList.add("hidden");
      this.stripeFormContainerTarget.classList.remove("hidden");
    }
  }

  toggleCreditCardState() {
    let showAddButton =
      this.addPaymentMethodTarget.classList.contains("hidden");

    if (showAddButton) {
      this.existingPaymentMethodsTarget.classList.add("hidden");
      this.addPaymentMethodTarget.classList.remove("hidden");
      this.addPaymentMethodButtonTarget.classList.remove("hidden");
      this.submitExistingPaymentMethodButtonTarget.classList.add("hidden");
      this.creditCardButtonTarget.innerHTML = "Use existing credit card";
    } else {
      this.existingPaymentMethodsTarget.classList.remove("hidden");
      this.addPaymentMethodTarget.classList.add("hidden");
      this.addPaymentMethodButtonTarget.classList.add("hidden");
      this.submitExistingPaymentMethodButtonTarget.classList.remove("hidden");
      this.creditCardButtonTarget.innerHTML = "Add new credit card";
    }
  }
}
