import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  closeAppBanner() {
    this.element.classList.add("hidden");
    localStorage.setItem("appBanner", "closed");
  }

  showAppBanner() {
    if (localStorage.getItem("appBanner") == "closed") {
      return;
    }
    this.element.classList.remove("hidden");
  }

  connect() {
    this.showAppBanner();
  }
}
