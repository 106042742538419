import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openInApp(event) {
    const mobileTargetUrl = event.currentTarget.dataset.mobileTargetUrl;
    const linkTargetUrl = event.currentTarget.dataset.linkTargetUrl;

    if (mobileTargetUrl) {
      event.preventDefault();
      window.location = mobileTargetUrl;
      setTimeout(function() {
        window.location = linkTargetUrl;
      }, 25);
    } else {
      // if there is no mobile URL, we allow the original click to proceed
    }
  };
}
