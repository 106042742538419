import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "select" ]

  initialize() {
    this.selectize();
  }

  selectize() {
    var valueField = this.selectTarget.dataset.valueField || "id";
    var labelField = this.selectTarget.dataset.labelField || "name";
    var searchClasses = JSON.parse(this.selectTarget.dataset.classes);

    jQuery(this.selectTarget).selectize({
      plugins: {
        clear_button: {
          label: "Clear"
        }
      },
      valueField: valueField,
      labelField: labelField,
      searchField: [labelField, valueField],
      create: false,
      load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: "/admin/search",
          type: 'GET',
          dataType: "json",
          data: {
            search_term: query,
            search_classes: searchClasses,
          },
          error: function() {
            callback();
          },
          success: function(res) {
            callback(res);
          }
        });
      }
    });
  }
}
