import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =['allToggle', 'emailToggle', 'pushToggle', 'allLabel', 'emailLabel', 'pushLabel', 'push', 'email'];

  initialize() {
    this.updateLabels();
  }

  updateLabels() {
    this.allLabelTarget.innerHTML = this.allToggleTarget.checked ? "Disable all notifications" : "Enable all notifications";
    this.emailLabelTarget.innerHTML = this.emailToggleTarget.checked ? "Disable all email notifications" : "Enable all email notifications";
    this.pushLabelTarget.innerHTML = this.pushToggleTarget.checked ? "Disable all push notifications" : "Enable all push notifications";
  }

  disableAll(event) {
    this.setCheckboxValues(this.emailTargets, this.allToggleTarget.checked);
    this.setCheckboxValues(this.pushTargets, this.allToggleTarget.checked);
    this.emailToggleTarget.checked = this.allToggleTarget.checked;
    this.pushToggleTarget.checked = this.allToggleTarget.checked;
    this.updateLabels();
  }

  disableAllEmailNotifications(event) {
    this.setCheckboxValues(this.emailTargets, this.emailToggleTarget.checked);
    this.allToggleTarget.checked = this.emailToggleTarget.checked || this.pushToggleTarget.checked;
    this.updateLabels();
  }

  disableAllPushNotifications(event) {
    this.setCheckboxValues(this.pushTargets, this.pushToggleTarget.checked);
    this.allToggleTarget.checked = this.emailToggleTarget.checked || this.pushToggleTarget.checked;
    this.updateLabels();
  }

  setCheckboxValues(targets, value) {
    for (let i = 0; i < targets.length; i++) {
      targets[i].checked = value;
    }
  }
}
