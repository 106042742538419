import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =['timestamp'];

  initialize() {
    this.formatDate();
  }

  formatDate() {
    this.timestampTarget.innerHTML = this.formattedTime();
  }

  formattedTime() {
    let date = new Date(parseInt(this.timestampTarget.dataset.timestamp));
    return date.toLocaleString("en-US", { "dateStyle": "medium" });
  }
}
