import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "map" ]

  connect() {
    this.initializeMap();
  }

  initializeMap() {
    if (typeof(google) == "undefined") {
      return;
    }

    if (this.mapTarget) {
      const mapsLoad = google.maps.importLibrary("maps");
      const markerLoad = google.maps.importLibrary("marker");
      Promise.all([mapsLoad, markerLoad]).then(([ maps, marker ]) => this.initializeMapWithLibraries(maps, marker));
    }
  }

  initializeMapWithLibraries(maps, marker) {
    const coordinates = {
      lat: parseFloat(this.mapTarget.dataset.latitude),
      lng: parseFloat(this.mapTarget.dataset.longitude)
    };

    const map = new maps.Map(this.mapTarget, {
      center: coordinates,
      zoom: 8,
      styles: this.mapStyles()
    });

    new marker.Marker({
      map,
      icon: "map-icon.svg",
      clickable: false,
      position: coordinates,
    });
  }

  mapStyles() {
    return [
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#444444"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
          {
            "color": "#f2f2f2"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": 45
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "color": "#dbdbdb"
          },
          {
            "visibility": "on"
          }
        ]
      }
    ];
  }
}
