import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "eventContainer" ]

  initialize() {
    // Since we're using Turbo, and the header is not refreshed on each request, then including the Meta pixel events in
    // the header won't work for anything but the first page view. Instead, we use this which will send the given event
    // when the div is instantiated.
    this.sendEvent();
  }

  sendEvent() {
    let eventType;
    let eventName;

    if (this.eventContainerTarget) {
      eventType = this.eventContainerTarget.dataset.eventType;
      eventName = this.eventContainerTarget.dataset.eventName;
      fbq(eventType, eventName);
    }
  }
}
