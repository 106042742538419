import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "loadingScreen",
    "customBidPrice",
    "selectedShippingPrice",
    "selectedTaxPrice",
    "selectedTotalPrice",
    "selectedDiscountPrice",
  ];

  toggleCustomBidFieldVisibility(event) {
    if (event.target.value == "other") {
      this.customBidPriceTarget.classList.remove("hidden");
    } else {
      this.customBidPriceTarget.classList.add("hidden");
    }
  }

  showLoading() {
    this.loadingScreenTarget.classList.remove("hidden");
    this.formTarget.classList.add("hidden");
  }

  updateSelectedQuoteValues(event) {
    let dataset = event.target.dataset;
    this.selectedShippingPriceTarget.innerHTML = dataset.shippingPrice;
    this.selectedTaxPriceTarget.innerHTML = dataset.taxPrice;
    this.selectedTotalPriceTarget.innerHTML = dataset.totalPrice;
    this.selectedDiscountPriceTarget.innerHTML = dataset.discountPrice;
  }
}
