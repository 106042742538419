import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "stateSelectList",
    "countrySelectList",
    "autocomplete",
    "cityField",
    "zipcodeField",
  ];

  connect() {
    this.initializeAutoComplete();
  }

  initializeAutoComplete() {
    if (typeof google == "undefined") {
      return;
    }

    google.maps.importLibrary("places").then((places) => this.initializeAutoCompleteWithLibraries(places));
  }

  initializeAutoCompleteWithLibraries(places) {
    this.autocomplete = new places.Autocomplete(this.autocompleteTarget, { types: ["geocode"] });
    this.autocomplete.addListener("place_changed", () => this.placeChanged());
    this.autocompleteTarget.addEventListener('keydown', function(event) {
      const addressContainer = document.querySelector('.pac-container');
      const addressContainerIsVisible = addressContainer.offsetParent != null;
      // prevents submitting the form when using the "enter" key to select the autocomplete suggestion
      if (addressContainerIsVisible && event.keyCode === 13) { event.preventDefault(); }
    });
  }

  updateStatesSelectList({ googlePlacesSelectedState = null }) {
    if (this.countrySelectListTarget.value != "") {
      fetch("/addresses/states?country_code=" + this.countrySelectListTarget.value)
        .then((response) => response.json())
        .then((data) => this.setStatesSelectList(data, googlePlacesSelectedState));
    } else {
      this.setStatesSelectList([], googlePlacesSelectedState);
    }
  }

  setStatesSelectList(data, googlePlacesSelectedState) {
    const emptyOption = this.stateSelectListTarget.querySelector("option[value='']");
    const emptyOptionHTML = (emptyOption != null) ? emptyOption.outerHTML : "";
    let options = data
      .map((state) => `<option value="${state[0]}">${state[1]}</option>`)
      .join("");
    this.stateSelectListTarget.innerHTML = emptyOptionHTML + options;

    const stateValues = data.map((state) => state[0]);
    const stateValue = [
      this.countrySelectListTarget.value,
      "-",
      googlePlacesSelectedState,
    ].join("");
    const valueExists = stateValues.includes(stateValue);

    if (valueExists) {
      this.stateSelectListTarget.value = stateValue;
    } else {
      this.stateSelectListTarget.selectedIndex = 0;
    }
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    this.extractAddressComponents(place);
  }

  getComponent(place, componentTypes) {
    if (!place || !place.address_components) {
      return null;
    }

    componentTypes = Array.isArray(componentTypes)
      ? componentTypes
      : [componentTypes];

    return place.address_components.find((component) =>
      component.types.some((type) => componentTypes.includes(type))
    );
  }

  extractAddressComponents(place) {
    const countryComponent = this.getComponent(place, "country");
    this.countrySelectListTarget.value = countryComponent.short_name;

    const stateComponent = this.getComponent(
      place,
      "administrative_area_level_1"
    );
    this.updateStatesSelectList({
      googlePlacesSelectedState: stateComponent.short_name,
    });

    const postalcodeComponent = this.getComponent(place, "postal_code");
    this.zipcodeFieldTarget.value = postalcodeComponent?.long_name ?? "";

    const localityComponent = this.getComponent(place, "locality");
    const adminArea2Component = this.getComponent(
      place,
      "administrative_area_level_2"
    );
    const localityLongName = localityComponent?.long_name ?? null;
    const adminArea2LongName = adminArea2Component?.long_name ?? null;
    const city = localityLongName || adminArea2LongName;
    this.cityFieldTarget.value = city;

    const streetNumberComponent = this.getComponent(place, "street_number");
    const routeCompontent = this.getComponent(place, "route");
    const streetLongName = streetNumberComponent?.long_name ?? "";
    const routeLongName = routeCompontent?.long_name ?? "";
    this.autocompleteTarget.value = [streetLongName, routeLongName]
      .join(" ")
      .trim();
  }
}
