import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "navigationContainer",
    "mainComponents",
    "additionalComponents",
    "mainNavigationItems",
    "additionalNavigationItems",
  ];

  static values = {
    selectedNavigationItemClasses: Array,
    unselectedNavigationItemClasses: Array,
    itemIds: Array,
  };

  connect() {
    this.handleFragmentSelectionOnPageLoad();
    window.addEventListener("scroll", this.checkPosition.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.checkPosition.bind(this));
  }

  checkPosition() {
    const stickyRect = this.navigationContainerTarget.getBoundingClientRect();
    const scrollingTargets = this.itemIdsValue.map((id) =>
      document.getElementById(id)
    );
    scrollingTargets.forEach((div, index) => {
      const rect = div.getBoundingClientRect();
      if (rect.top < stickyRect.bottom && rect.bottom > stickyRect.bottom) {
        const selector = `a[href$="${div.id}"]`;
        const navigationTag =
          this.navigationContainerTarget.querySelector(selector);
        this.selectNavigationItem(navigationTag);
      }
    });
  }

  handleFragmentSelectionOnPageLoad() {
    const location_hash = window.location.hash;
    if (location_hash != "") {
      const selector = `a[href$="${location_hash}"]`;
      const navigationTag =
        this.navigationContainerTarget.querySelector(selector);
      this.selectNavigationItem(navigationTag);
    }
  }

  selectNavigationItemEvent(event) {
    this.selectNavigationItem(event.currentTarget);
  }

  selectNavigationItem(element) {
    this.resetCssClasses();
    this.selectRequiredNavigationItem(element);
  }

  resetCssClasses() {
    this.navigationContainerTarget.querySelectorAll("a").forEach((a) => {
      a.classList.remove(...this.selectedNavigationItemClassesValue);
      a.classList.add(...this.unselectedNavigationItemClassesValue);
    });
  }

  selectRequiredNavigationItem(element) {
    element.classList.remove(...this.unselectedNavigationItemClassesValue);
    element.classList.add(...this.selectedNavigationItemClassesValue);
  }

  showAdditionalDetails() {
    this.mainComponentsTarget.classList.add("hidden");
    this.additionalComponentsTarget.classList.remove("hidden");
    this.mainNavigationItemsTarget.classList.add("hidden");
    this.additionalNavigationItemsTarget.classList.remove("hidden");
    this.additionalNavigationItemsTarget.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  showMainDetails() {
    this.mainComponentsTarget.classList.remove("hidden");
    this.additionalComponentsTarget.classList.add("hidden");
    this.mainNavigationItemsTarget.classList.remove("hidden");
    this.additionalNavigationItemsTarget.classList.add("hidden");
  }
}
