import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    requestedResource: String,
  };

  connect() {
    let navigationItem = document.getElementById(this.requestedResourceValue);
    let navigationItemSection = navigationItem.parentNode.parentNode.parentNode;
    navigationItemSection.scrollIntoView();
  }
}
