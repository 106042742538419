import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // ES6 doesn't support class variables, so you'll need to provide a static getter.
  // https://github.com/hotwired/stimulus/issues/79

  // Flash Banner
  // hide flash banner onClick event
  closeFlashBanner() {
    const flashBanner = document.getElementById("flashBanner");

    flashBanner.classList.remove("flex");
    flashBanner.classList.add("hidden");
  };
}
