import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // ES6 doesn't support class variables, so you'll need to provide a static getter.
  // https://github.com/hotwired/stimulus/issues/79

  // Modal
  // hide modal onClick event
  closeModal(event) {
    const modal = event.target.closest(".modal");
    const body = document.getElementsByTagName("body")[0];
    const modalOverlay = modal.querySelector('.overlay');
    const modalContent = modal.querySelector('.modal-content');

    body.classList.remove("overflow-hidden");
    modal.classList.add("translate-y-full");
    modalOverlay.classList.add("opacity-0");
    modalContent.classList.add("opacity-0", "translate-y-1/3");
  };

  // show modal onClick event
  showModal(event) {
    const modalId = event.target.getAttribute("data-modal-id");
    const body = document.getElementsByTagName("body")[0];
    const modal = document.getElementById(modalId);
    const modalOverlay = modal.querySelector('.overlay');
    const modalContent = modal.querySelector('.modal-content');

    body.classList.add("overflow-hidden");
    modal.classList.remove("translate-y-full");
    modalOverlay.classList.remove("opacity-0");
    modalContent.classList.remove("opacity-0", "translate-y-1/3");
  };
}
