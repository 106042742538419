import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['firstName', 'lastName', 'artistName'];
  }

  prePopulateArtistName(event) {
    this.artistNameTarget.value = this.firstNameTarget.value.concat(" ", this.lastNameTarget.value)
  }
}
