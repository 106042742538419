import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"];

  initialize() {
    this.openTab(this.tabTargets[0]);
  }

  choose(event) {
    this.openTab(event.target);
  }

  openTab(tabToOpen) {
    this.tabTargets.forEach(function(tab) {
      var tabContents = document.getElementById(tab.dataset.tabContent);
      if (tab == tabToOpen) {
        tabContents.classList.remove("hidden");
        tab.classList.add("border-black", "font-medium", "text-black");
        tab.classList.remove("border-transparent");
      } else {
        tabContents.classList.add("hidden");
        tab.classList.remove("border-black", "font-medium", "text-black");
        tab.classList.add("border-transparent");
      }
    });
  };
}
