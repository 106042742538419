import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, interval: Number }

  connect() {
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.interval = setInterval(() => {
      this.refresh()
    }, this.intervalValue)
  }

  stopRefreshing() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  refresh() {
    this.element.src = this.urlValue;
  }
}
